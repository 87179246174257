import React, { useEffect, useState } from 'react';

const Sitemap = () => {
  const [sitemapContent, setSitemapContent] = useState('');

  useEffect(() => {
    fetch('/sitemap.xml')
      .then((response) => response.text())
      .then((data) => {
        setSitemapContent(data);
      })
      .catch((error) => console.error('Error fetching sitemap:', error));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: sitemapContent }} />;
};

export default Sitemap;
