import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import Sitemap from 'src/components/sitemap/SiteMap';

// ----------------------------------------------------------------------

export const HomePage1 = lazy(() => import('src/pages/home'));
export const HomePage = lazy(() => import('src/pages/home/list'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
// Contact and leagals
const PrivacyPage = lazy(() => import('src/pages/privacy'));
const Terms = lazy(() => import('src/pages/terms'));

const PostListPage = lazy(() => import('src/pages/home/list'));
const PostDetailsPage = lazy(() => import('src/pages/home/details'));

// PAGES
const LatestNewsPage = lazy(() => import('src/pages/latest-news/list'));
const LatestNewsDetailsPage = lazy(() => import('src/pages/latest-news/details'));

const CrimeNewsPage = lazy(() => import('src/pages/crime-news/list'));
const CrimeNewsDetailsPage = lazy(() => import('src/pages/crime-news/details'));

const BangladeshPage = lazy(() => import('src/pages/bangladesh/list'));
const BangladeshDetailsPage = lazy(() => import('src/pages/bangladesh/details'));

const PoliticsPage = lazy(() => import('src/pages/politics/list'));
const PoliticsDetailsPage = lazy(() => import('src/pages/politics/details'));

const InternationalNewsPage = lazy(() => import('src/pages/international-news/list'));
const InternationalDetailsPage = lazy(() => import('src/pages/international-news/details'));

const RecentNewsPage = lazy(() => import('src/pages/recent-news/list'));
const RecentNewsDetailsPage = lazy(() => import('src/pages/recent-news/details'));

const TheEconomyPage = lazy(() => import('src/pages/the-economy/list'));
const TheEconomyDetailsPage = lazy(() => import('src/pages/the-economy/details'));

const SportsNewsPage = lazy(() => import('src/pages/sports-news/list'));
const SportsNewsDetailsPage = lazy(() => import('src/pages/sports-news/details'));

const EntertainmentPage = lazy(() => import('src/pages/entertainment/list'));
const EntertainmentDetailsPage = lazy(() => import('src/pages/entertainment/details'));

const JobNewsPage = lazy(() => import('src/pages/job-news/list'));
const JobNewsDetailsPage = lazy(() => import('src/pages/job-news/details'));

const OpinionPage = lazy(() => import('src/pages/opinion/list'));
const OpinionDetailsPage = lazy(() => import('src/pages/opinion/details'));

const ExpatriateLifePage = lazy(() => import('src/pages/expatriate-life/list'));
const ExpatriateLifeDetailsPage = lazy(() => import('src/pages/expatriate-life/details'));

const LifeStylePage = lazy(() => import('src/pages/life-style/list'));
const LifeStyleDetailsPage = lazy(() => import('src/pages/life-style/details'));

const VideosPage = lazy(() => import('src/pages/videos/list'));
const VideosDetailsPage = lazy(() => import('src/pages/videos/details'));

const BusinessPage = lazy(() => import('src/pages/business/list'));
const BusinessDetailsPage = lazy(() => import('src/pages/business/details'));

const Logo = lazy(() => import('src/components/logo'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'sitemap.xml', element: <Sitemap /> },
      { path: 'aboutus', element: <AboutPage /> },
      { path: 'contactus', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      { path: 'logo', element: <Logo /> },
      // Contact And Legals
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'terms-condition', element: <Terms /> },

      {
        path: 'post',
        children: [
          { element: <PostListPage />, index: true },
          { path: 'list', element: <PostListPage /> },
          { path: ':title', element: <PostDetailsPage /> },
        ],
      },
      {
        path: 'latest-news',
        children: [
          { element: <LatestNewsPage />, index: true },
          { path: 'list', element: <LatestNewsPage /> },
          { path: ':title', element: <LatestNewsDetailsPage /> },
        ],
      },
      {
        path: 'crime-news',
        children: [
          { element: <CrimeNewsPage />, index: true },
          { path: 'list', element: <CrimeNewsPage /> },
          { path: ':title', element: <CrimeNewsDetailsPage /> },
        ],
      },
      {
        path: 'bangladesh',
        children: [
          { element: <BangladeshPage />, index: true },
          { path: 'list', element: <BangladeshPage /> },
          { path: ':title', element: <BangladeshDetailsPage /> },
        ],
      },
      {
        path: 'politics',
        children: [
          { element: <PoliticsPage />, index: true },
          { path: 'list', element: <PoliticsPage /> },
          { path: ':title', element: <PoliticsDetailsPage /> },
        ],
      },
      {
        path: 'international-news',
        children: [
          { element: <InternationalNewsPage />, index: true },
          { path: 'list', element: <InternationalNewsPage /> },
          { path: ':title', element: <InternationalDetailsPage /> },
        ],
      },
      {
        path: 'recent-news',
        children: [
          { element: <RecentNewsPage />, index: true },
          { path: 'list', element: <RecentNewsPage /> },
          { path: ':title', element: <RecentNewsDetailsPage /> },
        ],
      },
      {
        path: 'the-economy',
        children: [
          { element: <TheEconomyPage />, index: true },
          { path: 'list', element: <TheEconomyPage /> },
          { path: ':title', element: <TheEconomyDetailsPage /> },
        ],
      },
      {
        path: 'sports-news',
        children: [
          { element: <SportsNewsPage />, index: true },
          { path: 'list', element: <SportsNewsPage /> },
          { path: ':title', element: <SportsNewsDetailsPage /> },
        ],
      },
      {
        path: 'entertainment',
        children: [
          { element: <EntertainmentPage />, index: true },
          { path: 'list', element: <EntertainmentPage /> },
          { path: ':title', element: <EntertainmentDetailsPage /> },
        ],
      },
      {
        path: 'job-news',
        children: [
          { element: <JobNewsPage />, index: true },
          { path: 'list', element: <JobNewsPage /> },
          { path: ':title', element: <JobNewsDetailsPage /> },
        ],
      },
      {
        path: 'opinion',
        children: [
          { element: <OpinionPage />, index: true },
          { path: 'list', element: <OpinionPage /> },
          { path: ':title', element: <OpinionDetailsPage /> },
        ],
      },
      {
        path: 'expatriate-life',
        children: [
          { element: <ExpatriateLifePage />, index: true },
          { path: 'list', element: <ExpatriateLifePage /> },
          { path: ':title', element: <ExpatriateLifeDetailsPage /> },
        ],
      },
      {
        path: 'life-style',
        children: [
          { element: <LifeStylePage />, index: true },
          { path: 'list', element: <LifeStylePage /> },
          { path: ':title', element: <LifeStyleDetailsPage /> },
        ],
      },
      {
        path: 'videos',
        children: [
          { element: <VideosPage />, index: true },
          { path: 'list', element: <VideosPage /> },
          { path: ':title', element: <VideosDetailsPage /> },
        ],
      },
      {
        path: 'business-news',
        children: [
          { element: <BusinessPage />, index: true },
          { path: 'list', element: <BusinessPage /> },
          { path: ':title', element: <BusinessDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      // { path: 'coming-soon', element: <ComingSoonPage /> },
      // { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
